.container {
  margin: 20px 0 5px 0;
  display: flex;
  font-size: 1rem;
  color: #6e6e6e;
  font-weight: 400;
  text-shadow: #00000040 0.1em 0.1em 0.1em;
}

.item {
  display: flex;
  align-items: center;
}

.divider {
  font-size: 1rem;
  color: #6e6e6e;
  font-weight: 400;

  & + div::after {
    content: " > ";
  }
}

.container {
  div:last-child {
    & ::after {
      content: "";
    }
  }
  // div:first-child {
  //   & ::after {
  //     content: "";
  //   }
  // }
}

.breadOption {
  display: flex;
  align-items: center;
  a {
    font-size: 0.825rem;
  }

  &.breadActive {
    color: #6e6e6e;
    font-weight: 600;
  }
  &.breadPrevious {
    color: #6e6e6e;
    font-weight: 200;
    margin-right: 0px;
  }
}
