@use '../Color/style.scss' as colors;

.table {
    width: 100%;
    overflow: auto;
    min-height: 300px;
    border-radius: 5px;
    border: 1px solid #CED4DA;

    @each $color, $hex in colors.$colors {
        &#{$color}>table>thead {
            background-color: lighten($hex, 15);
            transition: background-color .3s;

            &:hover {
                background-color: map-get(colors.$colorsHover, $color);
            }
        }
    }

    &.--hover>table>tbody>tr:hover {
        background-color: colors.$unabled;
        opacity: .7;
        cursor: pointer;
    }

    &>table {
        font-variant-numeric: lining-nums tabular-nums;
        width: 100%;

        &>thead {
            font-size: 14px;
            position: sticky;
            top: 0;
            z-index: 1;

            &>tr {
                white-space: nowrap;

                &>th {
                    box-sizing: border-box;
                    padding: .3rem;
                    text-align: left;
                    opacity: .7;

                    &:first-of-type {
                        border-top-left-radius: 4px;
                    }

                    &:last-of-type {
                        border-top-right-radius: 4px;
                    }
                }
            }
        }

        &>tbody {
            font-size: 15px;

            &:has(tr.--is_loading)>tr {
                pointer-events: none;
            }

            &>tr {
                transition: background-color .3s, opacity .3s;
                user-select: none;

                &.--is_loading {
                    position: relative;
                    opacity: .2;

                    &::after {
                        content: '\A';
                        background: url(../../assets/loader/dark.png) no-repeat center / cover;
                        width: 40px;
                        height: 40px;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                &>.--empty_message {
                    text-align: center;
                    font-weight: bold;
                    font-style: italic;
                }

                &:not(:last-of-type) {
                    border-bottom: 1px solid #CED4DA;
                }

                &>td {
                    padding: .8rem .4rem;
                    white-space: nowrap;
                    line-height: 1.25;

                    &>span {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 100%;
                        overflow: hidden;
                    }
                }
            }
        }
    }
}