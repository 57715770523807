@use '../../../components/Color/style.scss' as color;

.occurrence_details {
    box-sizing: border-box;
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, .1);
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    border-left: 1px solid rgba(0, 0, 0, .05);
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    transition: background-color .3s;

    &.--on_click {
        cursor: pointer;

        &:hover {
            background-color: rgba(0, 0, 0, .045);
        }
    }

    &>header {
        align-items: center;
        background-color: rgba(0, 0, 0, .04);
        display: flex;
        justify-content: space-between;
        padding: .2rem .3rem;

        &>.--responsible {
            font-style: italic;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-right: .2rem;
        }

        &>.--status>div {
            padding: .05rem .3rem;
        }
    }

    &>main {
        border-top: 1px solid rgba(0, 0, 0, .1);
        padding: .3rem 0 0 .3rem;

        &>.content {
            max-height: 100px;
            overflow: hidden;

            &:hover {
                overflow: auto;
            }
        }

        &>.reason {
            font-style: italic;
            font-size: 14px;
        }

        &>.logs {
            padding: .2rem;
            padding-right: .5rem;
            display: flex;
            flex-direction: column;
            gap: .5rem;

            & .logs__main {
                background-color: rgba(0, 0, 0, .06);
                padding: 0 .2rem;

                &>.reason {
                    font-style: italic;
                    font-size: 14px;
                }
            }

            & .logs__footer {
                background-color: rgba(0, 0, 0, .06);
                align-items: flex-end;
                display: flex;
                font-size: 13px;
                height: 19.5px;
                justify-content: space-between;
                padding: 0 .3rem;

                &>* {
                    white-space: nowrap;
                }

                &>.--reason {
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &>.--created_at {
                    color: color.$danger;
                    font-size: 10px;
                    font-style: italic;
                    width: fit-content;
                }
            }
        }
    }

    &>footer {
        align-items: flex-end;
        display: flex;
        font-size: 13px;
        height: 19.5px;
        justify-content: space-between;
        padding: 0 .3rem;

        &>* {
            white-space: nowrap;
        }

        &>.--doctor {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &>.--created_at {
            color: color.$danger;
            font-size: 10px;
            font-style: italic;
            width: fit-content;
        }
    }

    &.--is_loading {
        position: relative;
        pointer-events: none;

        &::before {
            content: '\A';
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            background: url(../../../assets/loader/light.png) no-repeat center / 30px, rgba(0, 0, 0, .2);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}