@import '../../Color/style.scss';

.grid_attendance>.filter {
  display: grid;
  grid-template-columns: repeat(12, minmax(0px, 1fr));
  gap: .2rem 1rem;
  margin-bottom: .5rem;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }

  &>*>.label {
    font-weight: bold;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &>.search {
    grid-column: 6 span;
    display: flex;
    align-items: center;
    align-self: flex-end;
    gap: 1rem;
    border: 1px solid #CED4DA;
    border-radius: 4px;
    padding: 0 .8rem;
    height: fit-content;
    width: 100%;

    &>span {
      opacity: .5;
      font-weight: bold;
      margin-right: 1rem;
      position: relative;

      @media screen and (max-width: 720px) {
        display: none;
      }

      &::after {
        content: '\A';
        position: absolute;
        right: -1.5rem;
        background: url(../../../images/search.svg) no-repeat center center / 15px 15px;
        width: 15px;
        height: 15px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    & input:focus-visible {
      border: none;
      box-shadow: unset;
    }
  }

  &>.filters {
    grid-column: 7 / 6 span;
  }

  &>.start_date,
  &>.end_date {
    grid-column: 3 span;
    z-index: 2;

    @media screen and (max-width: 1300px) {
      grid-column: 6 span;
    }

    &>.field label {
      display: flex;
      width: 100%;
      padding: 5px 12px;
      border: 1px solid #CED4DA;
      color: #8F8F8F;
      background-color: transparent;
      border-radius: 4px;
      align-content: center;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      transition: box-shadow .3s;
      position: relative;

      &>svg {
        position: absolute;
        right: 10px;
      }

      &:has(input:focus) {
        -webkit-box-shadow: 0px 0px 3px 0px rgba($primaryLight, 0.75);
        -moz-box-shadow: 0px 0px 3px 0px rgba($primaryLight, 0.75);
        box-shadow: 0px 0px 3px 0px rgba($primaryLight, 0.75);
      }

      & input {
        outline: none;
        width: 100%;
      }
    }
  }

  &>.actions {
    grid-column: 11 / 2 span;
    align-content: end;
  }

  @media screen and (max-width: 1300px) {
    &>.actions {
      margin-top: .3rem;
      grid-column: 12 span;
    }
  }

  &>* {
    max-width: 100%;
  }
}