@use '../Color/style.scss' as color;

.collapseable {
    position: relative;

    &.--modern {
        @each $color, $hex in color.$colors {
            &#{$color}>header {
                background-color: $hex;
            }
        }

        box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .2);

        &:not(:has(>main.--active)) {
            &>header {
                border-bottom-left-radius: 2px;
                border-bottom-right-radius: 2px;

                &::after {
                    rotate: 90deg;
                    transform: translate(-50%);
                }
            }
        }

        &>header {
            background-color: white;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            box-sizing: border-box;
            font-weight: 600;
            padding: .5rem;
            position: relative;
            transition:
                border-bottom-left-radius .3s,
                border-bottom-right-radius .3s,
                box-shadow .3s;

            &:hover {
                box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .05) inset;
            }

            &::after {
                content: '\A';
                background: url(../../assets/chevron-down-solid.svg) no-repeat center / cover;
                height: 12px;
                position: absolute;
                right: .5rem;
                top: 50%;
                transform: translateY(-50%);
                transition: rotate .3s, transform .3s;
                width: 12px;
            }
        }

        &>main {
            background-color: white;
            border-bottom-left-radius: 2px;
            border-bottom-right-radius: 2px;
            box-sizing: border-box;
            box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .0);

            &.--active {
                box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .2);
                padding: .5rem;
            }
        }
    }

    &.--badge {
        @each $color, $hex in color.$colors {
            &#{$color}>header {
                background-color: $hex;
            }
        }

        &:not(:has(>main.--active)) {
            &>header {
                border-bottom-left-radius: 2px;
                border-bottom-right-radius: 2px;

                &::after {
                    rotate: 90deg;
                    transform: translate(-50%);
                }
            }
        }

        &>header {
            align-items: center;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, .4);
            color: white;
            display: flex;
            font-size: 14px;
            font-weight: 600;
            gap: .3rem;
            height: fit-content;
            padding: .1rem .3rem;
            transition:
                filter .3s,
                border-bottom-left-radius .3s,
                border-bottom-right-radius .3s;
            text-shadow: 1px 1px 0px rgba(0, 0, 0, .5);
            white-space: nowrap;

            &:hover {
                filter: brightness(85%);
            }

            &::after {
                content: '\A';
                background: url(../../assets/chevron-down-solid.svg) no-repeat center / cover;
                height: 12px;
                position: absolute;
                right: .5rem;
                transform: translateY(-50%);
                transition: rotate .3s, transform .3s;
                top: 50%;
                width: 12px;
            }
        }

        &>main {
            background-color: white;
            border-bottom-left-radius: 2px;
            border-bottom-right-radius: 2px;
            box-sizing: border-box;
            box-shadow: none;
            
            &.--active {
                box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .2);
            }
        }
    }

    &>header {
        cursor: pointer;
    }

    &>main {
        background-color: unset;
        height: fit-content;
        max-height: 0px;
        position: absolute;
        transition: all .3s;
        width: 100%;
        overflow: hidden;
        z-index: 2;

        &:hover {
            overflow: auto;
        }

        &.--active {
            max-height: 30vh;
        }
    }
}