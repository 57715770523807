.ctm_textarea {

    &.--modern {
        &>textarea {
            border-bottom: 1px solid rgba(0, 0, 0, .35);
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .2);
            padding: 0.3rem 0.5rem;
            transition: box-shadow .3s;

            &:hover {
                box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .4);
            }
        }
    }

    &.--legacy {
        &>textarea {
            border: 1px solid rgb(210, 211, 229);
            border-radius: 8px;
            padding: 8px 10px;
        }
    }

    &>label {
        display: block;
        min-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }
    
    &>textarea {
        min-height: 35px;
        outline: none;
        width: 100%;
    }
}