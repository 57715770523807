@import '../Color/style.scss';

$menuBackgroundColor: white;
$hoverMenuItemBackgroundColor: $primaryLight;

$menuItemSpanColor: black;
$menuItemSpanHoverColor: white;

// menuItemIcon is only filter -> https://angel-rs.github.io/css-color-filter-generator/
$menuItemIconColor: brightness(0) saturate(100%);
$menuItemIconHoverColor: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7458%) hue-rotate(328deg) brightness(99%) contrast(96%);

$headerHeight: 3rem;
$footerHeight: 2.5rem;

$contentHeight: calc(100vh - $footerHeight - $headerHeight);
$noFooterContentHeight: calc(100vh - $headerHeight);

html {
    overflow: hidden;
}

header.header {
    $headerPaddingTopAndBottom: .3rem;

    background-color: $menuBackgroundColor;
    box-sizing: border-box;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, .2);
    display: flex;
    height: $headerHeight;
    justify-content: space-between;
    position: sticky;
    padding: $headerPaddingTopAndBottom 1rem;
    top: 0;
    transition: box-shadow .3s;
    width: 100%;
    z-index: 101;

    &>.header_logo {
        display: flex;

        @media screen and (max-width: 500px) {
            width: calc(100% - 1rem);

            &>.icon {
                width: 100%;
            }
        }

        &>.icon>svg {
            height: calc($headerHeight - 2 * $headerPaddingTopAndBottom);
            width: fit-content;
        }

        &>.header_aside_toggle {
            cursor: pointer;
            height: 100%;
            margin-left: 5rem;
            position: relative;
            transition: margin-left .3s;

            &:not(.--aside_is_open) {
                margin-left: 1rem;

                &::before {
                    background-size: 20px 20px;
                    opacity: 1;
                }
            }

            @media screen and (min-width: 500px) {
                &::before {
                    filter: $menuItemIconColor !important;
                    opacity: .5;
                    transition: opacity .5s;
                }

                &:not(.--aside_is_open):hover::before {
                    background-size: 22px 22px;
                }

                &:hover::before {
                    background-size: 20px 20px;
                }
            }


            &::before {
                background: url(../../assets/bars-solid.svg) no-repeat center center / 22px 22px;
                content: '\A';
                filter: brightness(0) saturate(100%) invert(98%) sepia(6%) saturate(3447%) hue-rotate(4deg) brightness(77%) contrast(91%);
                height: 35px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                transition: background-size .08s;
                width: 35px;
            }
        }
    }

    &>.header_hospital_active {
        align-content: center;
        pointer-events: none;

        &:not(.--no_edit) {
            pointer-events: all;

            &>span {
                cursor: pointer;
                position: relative;
                transition: text-shadow .3s;

                &:hover {
                    text-shadow: 1px 1px 2px rgba(0, 0, 0, .6);
                }

                &::after {
                    background: url(../../assets/pen-solid.svg) no-repeat center center / 12px 12px;
                    content: '\A';
                    filter: brightness(0) saturate(100%) invert(59%) sepia(83%) saturate(427%) hue-rotate(341deg) brightness(98%) contrast(95%);
                    height: 12px;
                    position: absolute;
                    right: -1rem;
                    top: calc(50% - .2rem);
                    transform: translateY(-50%);
                    width: 12px;
                }
            }
        }

        @media screen and (max-width: 800px) {
            display: none;
        }

        &>span {
            font-weight: 600;
            font-size: 14.5px;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, .4);
            text-transform: uppercase;
        }
    }

    &>.header_user {
        display: flex;
        gap: .5rem;

        @media screen and (max-width: 500px) {
            display: none;
        }

        &>.header_user_content {
            line-height: 1.1;
            align-content: center;
            font-weight: 600;
            font-size: 13px;

            &>.header_user_content_hospital {
                text-align: right;
            }

            &>.header_user_content_name {
                text-align: right;
            }
        }

        &>.header_user_image {
            align-content: center;

            &>svg {
                width: 35px;
            }
        }
    }
}

.aside {
    background-color: $menuBackgroundColor;
    box-shadow: 0 4px 4px 1px rgba(0, 0, 0, .2);
    box-sizing: border-box;
    height: $contentHeight;
    position: fixed;
    transition: width .3s;
    width: 60px;
    z-index: 101;

    @media screen and (min-width: 768px) {
        padding-top: 1rem;
    }

    @media screen and (max-width: 400px) {
        // height: $noFooterContentHeight;
    }

    &.--aside_is_open {
        width: 240px;

        & ul {
            overflow: auto;
        }
    }

    &:not(.--aside_is_open) {
        & ul {

            transition: width .5s;

            &:not(:first-child) {
                box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .2);
            }

            &>li {
                position: relative;

                &.--is_dropdown>a>span::after {
                    transform: translate(-50%, -50%) rotate(-90deg);
                    top: 50%;
                }

                &>ul {
                    position: absolute;
                    left: 100%;
                    top: 0;
                    width: 200px;
                    display: none;
                }

                &:hover>ul,
                &>ul:has(input:focus),
                &:hover ul:has(.--active) {
                    display: block;
                }
            }
        }
    }

    & ul {
        display: flex;
        flex-direction: column;
        height: 100%;
        list-style: none;

        @media screen and (max-width: 768px) {
            overflow: auto;
        }

        &>li {
            align-content: center;
            background-color: $menuBackgroundColor;
            color: $menuItemSpanColor;
            transition: background-color .3s;

            & input {
                background-color: transparent;
                width: 100%;
                outline: none;
                padding: .5rem .8rem;
                color: $menuItemSpanColor !important;

                &::placeholder {
                    color: $menuItemSpanColor !important;
                    opacity: .4;
                }
            }

            &:hover>ul,
            &>ul:has(input:focus, .--active) {
                height: 100vh;
            }

            &.--change_hospital {
                @media screen and (min-width: 800px) {
                    display: none;
                }
            }

            &.--logout_bottom {
                font-weight: bold;

                @media screen and (min-width: 800px) {
                    margin-top: auto;
                }
            }

            &.--hidden {
                display: none;
            }

            &>ul {
                height: 0px;
                max-height: fit-content;
                transition: height .8s;
            }

            &.--from_awesome>a>i>svg {
                height: 20px;
            }

            &:hover,
            &:has(>ul input:focus),
            &.--active,
            &:has(.--active) {
                color: $menuItemSpanHoverColor;

                &:not(:has(>input)) {
                    background-color: $hoverMenuItemBackgroundColor;
                }

                &>a {
                    opacity: 1;

                    &>i {
                        filter: $menuItemIconHoverColor;
                    }
                }
            }

            &.--is_dropdown>a>span::after {
                content: '\A';
                background: url(../../assets/chevron-down-solid.svg) no-repeat center center / 10px 10px;
                width: 10px;
                height: 10px;
                position: absolute;
                right: .5rem;
                top: 50%;
                transform: translateY(-50%);
                transition: top .3s;
            }

            &>a {
                align-items: center;
                display: flex;
                gap: .5rem;
                height: 50px;
                opacity: .6;
                padding: .2rem;
                padding-left: 1rem;

                &>span {
                    transition: color .3s;
                    white-space: nowrap;
                    width: 100%;
                    position: relative;
                }

                &:has(>i)>span {
                    @media screen and (min-width: 768px) {
                        display: none;
                    }
                }

                &>i {
                    align-content: center;
                    transition: filter .3s;

                    &>svg {
                        filter: $menuItemIconColor;
                        max-height: 25px;
                        width: 25px;
                    }
                }
            }
        }
    }

    &.--aside_is_open ul>li>a>span {
        display: block;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media screen and (max-width: 768px) {
        width: 0px;

        &.--aside_is_open {
            width: 240px;
            max-width: 100%;
        }
    }
}

.aside_content {
    background-color: #F7F8FC;
    height: $contentHeight;
    overflow: auto;
    margin-left: 60px;
    transition: margin-left .3s;

    &.--aside_is_open {
        margin-left: 240px;
    }

    @media screen and (max-width: 768px) {
        margin-left: 0px;

        &.--aside_is_open {
            margin-left: 0px;
        }
    }

    @media screen and (max-width: 400px) {
        // height: $noFooterContentHeight;
    }
}

.footer {
    align-items: center;
    background-color: $menuBackgroundColor;
    bottom: 0;
    box-shadow: 0 -1px 4px 1px rgba(0, 0, 0, .2);
    display: flex;
    height: $footerHeight;
    justify-content: right;
    position: sticky;
    width: 100%;
    z-index: 101;

    &__hospital_active {
        align-content: center;
        display: none;
        pointer-events: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 .2rem;
        width: 100%;
        text-align: center;

        &:not(.--no_edit) {
            pointer-events: all;

            &>span {
                cursor: pointer;
                position: relative;
                transition: text-shadow .3s;

                &:hover {
                    text-shadow: 1px 1px 2px rgba(0, 0, 0, .6);
                }

                &::after {
                    background: url(../../assets/pen-solid.svg) no-repeat center center / cover;
                    content: '\A';
                    filter: brightness(0) saturate(100%) invert(59%) sepia(83%) saturate(427%) hue-rotate(341deg) brightness(98%) contrast(95%);
                    height: 12px;
                    position: absolute;
                    right: -1rem;
                    top: calc(50% - .2rem);
                    transform: translateY(-50%);
                    width: 12px;
                }
            }
        }

        &>span {
            font-weight: 600;
            font-size: 14.5px;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, .4);
            text-transform: uppercase;
        }
    }

    @media screen and (max-width: 800px) {
        justify-content: center;


        &__hospital_active {
            display: block;
        }

        &>.footer_datetime {
            display: none;
        }
    }

    @media screen and (max-width: 500px) {
        font-size: 13px;
    }

    &>.footer_datetime {
        margin-right: 1.5rem;
        font-style: italic;
    }
}