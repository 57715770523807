.dashboard_container {
	width: 100%;
	padding: .5rem;
}

.dashboard_no_hospital_message {
	align-items: center;
	display: flex;
	height: 100%;
	width: 100%;
	justify-content: center;

	&>span {
		cursor: pointer;
		font-weight: 600;
		font-size: 14.5px;
		position: relative;
		text-shadow: 1px 1px 2px rgba(0, 0, 0, .4);
		text-transform: uppercase;
		transition: text-shadow .3s;

		&:hover {
			text-shadow: 1px 1px 2px rgba(0, 0, 0, .6);
		}

		&::after {
			background: url(../../assets/pen-solid.svg) no-repeat center center / 12px 12px;
			content: '\A';
			filter: brightness(0) saturate(100%) invert(59%) sepia(83%) saturate(427%) hue-rotate(341deg) brightness(98%) contrast(95%);
			height: 12px;
			position: absolute;
			right: -1rem;
			top: calc(50% - .2rem);
			transform: translateY(-50%);
			width: 12px;
		}
	}
}