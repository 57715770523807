.active_hospital__modal_content {
    padding: 1rem;

    &>header {
        font-weight: 600;
        text-align: center;
    }

    &>main {
        margin-top: 1rem;

        &>select {
            background-color: transparent;
            border-bottom: 1px solid rgba(0, 0, 0, .3);
            box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .2);
            cursor: pointer;
            font-size: 14px;
            outline: none;
            padding: .3rem;
            transition: border-bottom .3s, background-color .3s;
            width: 100%;

            &[disabled] {
                background-color: #eee;
            }

            &:hover, &:focus {
                border-bottom: 1px solid rgba(0, 0, 0, .7);
            }
        }
    }
}