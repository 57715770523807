@use '../Color/style.scss' as color;
@use 'sass:map';

.ctm_button {
    &>label {
        display: block;
        min-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    &>button {
        border-radius: 2px;
        font-weight: 600;
        height: fit-content;
        min-height: 34px;
        min-width: fit-content;
        position: relative;
        transition: background-color .3s, color .3s;
        width: 100%;

        @each $name, $hex in color.$colors {
            &#{$name} {
                background-color: $hex;
                color: map-get(color.$colorsFont, $name);

                &:hover {
                    background-color: map-get(color.$colorsHover, $name);
                }
            }
        }

        &.--is_loading {
            background-color: color.$unabled;
            pointer-events: none;

            &::before {
                background: url(../../assets/loader/light.png) no-repeat center / cover;
                content: '\A';
                height: 30px;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 30px;
            }
        }
    }
}