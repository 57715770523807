@use "../../components/Color/style.scss" as color;
@use "sass:map";

.report {
    display: grid;
    grid-template-columns: repeat(12, minmax(0px, 1fr));
    padding: 1.5rem;
    gap: .2rem;

    &.--is_loading form {
        & select,
        & input,
        & button {
            pointer-events: none !important;
            background-color: color.$unabled !important;
            cursor: not-allowed;
        }

        & button[type=submit] {
            position: relative;

            &::after {
                content: '\A';
                background: url(../../assets/loader/light.png) no-repeat center center / 20px 20px;
                width: 20px;
                height: 20px;
                position: absolute;
                right: .3rem;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    & .pdf_result {
        grid-column: 12 span;
        background-color: #222;
        width: 100%;
        padding: .5rem;
        overflow: auto;
        max-height: calc(100vh - 25.5rem);
        display: flex;
        justify-content: center;

        @media screen and (max-width: 720px) {
            max-height: calc(100vh - 29.5rem);
        }
    }

    &>.title {
        grid-column: 12 span;
        margin-bottom: .5rem;
        display: flex;
        justify-content: space-between;

        &>span {
            font-weight: 600;
            font-size: 21px;
        }

        &>button {
            background-color: color.$primary;
            border-radius: 2px;
            text-shadow: 1px 1px 4px rgba(0, 0, 0, .1);
            transition: background-color .3s;
            padding: .2rem .5rem;

            &>svg {
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7458%) hue-rotate(328deg) brightness(99%) contrast(96%);
            }

            &:hover {
                background-color: map-get(color.$colorsHover, '.--primary');
            }
        }
    }

    &>.params {
        grid-column: 6 span;
        padding: .5rem;
        background-color: white;
        box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .1);
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &>.title {
            font-weight: 600;
        }

        &>.fields {
            display: grid;
            grid-template-columns: repeat(12, minmax(0px, 1fr));
            gap: .5rem;

            &>.field {
                grid-column: 6 span;
            }
        }

        &>.actions {
            display: grid;
            grid-template-columns: repeat(4, minmax(0px, 1fr));

            &>.generate {
                grid-column: 4 / 1 span;
                background-color: color.$primary;
                color: white;
                padding: .1rem .4rem;
                border-radius: 2px;
                font-weight: 600;
                transition: background-color .3s;
                user-select: none;

                &[disabled] {
                    background-color: color.$unabled;
                    cursor: progress;
                    pointer-events: none;
                }

                &:not([disabled]):hover {
                    background-color: map-get(color.$colorsHover, '.--primary');
                }

                @media screen and (max-width: 1300px) {
                    grid-column: 3 / 2 span;
                }
            }

            @media screen and (max-width: 720px) {
                display: flex;
                flex-direction: column;
            }
        }

        @media screen and (max-width: 720px) {
            grid-column: span 12;

            &>.fields,
            &>.actions {
                display: flex;
                flex-direction: column;
            }
        }
    }
}