@use '../Color/style.scss' as color;
@use 'sass:map';

.badge {
    align-items: center;
    border-radius: 2px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, .4);
    color: white;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    gap: .3rem;
    height: fit-content;
    justify-content: center;
    max-width: fit-content;
    padding: .3rem;
    transition: filter .3s;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, .5);
    white-space: nowrap;
    
    @each $color, $hex in color.$colors {
        &#{$color} {
            background-color: $hex;
            color: map-get($map: color.$colorsFont, $key: $color);
        }
    }

    &.--clickable {
        cursor: pointer;

        &:hover {
            filter: brightness(85%);
        }
    }

    &.badge_calendar {
        position: relative;
        font-weight: 600;

        &>img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(272deg) brightness(102%) contrast(101%);
        }

        &.--ie_before {
            & img {
                margin-left: .5rem;
            }

            &::before {
                content: '!';
                position: absolute;
                left: 5px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &.--ie_after {
            & img {
                margin-right: .5rem;
            }

            &::after {
                content: '!';
                position: absolute;
                right: 5px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    &>.occurrence {
        filter: brightness(0) saturate(100%) invert(100%) sepia(2%) saturate(0%) hue-rotate(213deg) brightness(105%) contrast(103%);
    }

    & img,
    & svg {
        height: 16px;
    }
}