@use '../Color/style.scss' as color;
@use 'sass:map';

$ulOptionsLegacyBorder: 1px solid black;
$ulOptionsListBoxShadow: 1px 1px 4px 1px rgba(0, 0, 0, .4);

@mixin mobileSelectOptions() {
    position: relative;

    &>div.overlay {
        align-items: center;
        background-color: rgba(0, 0, 0, .2);
        display: flex;
        height: 100vh;
        justify-content: center;
        left: 0;
        opacity: 1;
        position: fixed;
        transition: opacity .3s ease, visibility .3s ease, background-color .3s;
        top: 0;
        visibility: visible;
        width: 100vw;
        z-index: 120;

        &:not(:has(ul.options.--is_open)) {
            opacity: 0;
            visibility: hidden;
        }

        &>ul.options {
            width: 95vw;
            margin-top: 0;
            max-width: 450px;
        }
    }
}

.will_select {
    position: relative;

    &.--legacy {
        &>div.select_input {
            border: 1px solid rgb(210, 211, 229);
            border-radius: 8px;
            padding: 8px 10px;
            height: 37px;

            &::before {
                content: '\A';
                background: url(../../assets/chevron-down-solid.svg) no-repeat center / cover;
                width: 10px;
                height: 10px;
                position: absolute;
                right: .7rem;
                top: 50%;
                transform: translateY(-50%);
            }

            &>.multiple_counter {
                &::after {
                    background-color: color.$primary;
                    border-radius: 50%;
                    font-weight: 600;
                    color: white;
                }
            }
        }
    }

    &.--modern {

        &>div.select_input:hover,
        &:has(>div.select_options>div.overlay>ul.options.--is_open)>div.select_input {
            box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .4);
        }

        &>div.select_input {
            box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .2);
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            border-bottom: 1px solid rgba(0, 0, 0, .35);
            padding: .3rem .5rem;
            transition: box-shadow .3s;

            &:has(>.multiple_counter[content])>div.placeholder {
                width: calc(100% - (1.8rem + .8rem));
            }

            &>div.placeholder {
                box-sizing: border-box;
                padding: 2px;
            }

            &::before {
                content: '\A';
                background: url(../../assets/chevron-down-solid.svg) no-repeat center / cover;
                width: 10px;
                height: 10px;
                position: absolute;
                right: .7rem;
                top: 50%;
                transform: translateY(-50%);
            }

            &>.multiple_counter {
                &::after {
                    background-color: color.$primary;
                    border-radius: 50%;
                    font-weight: 600;
                    color: white;
                }
            }

            &>button.--clear {
                &:hover {
                    right: 0;
                    width: 50px;
                    height: 100%;
                    border-radius: 0;
                    border-top-right-radius: 2px;
                }

                &>svg {
                    width: 10px;
                    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7458%) hue-rotate(328deg) brightness(99%) contrast(96%);
                }
            }
        }
    }

    &:not(.--has_value)>div.select_input>button.--clear {
        opacity: 0;
    }

    &.--is_loading::after {
        background: url(../../assets/loader/unabled.png) no-repeat center center / contain;
        content: '\A';
        height: 25px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateX(-50%);
        width: 25px;
    }

    &.--disabled {
        pointer-events: none;

        &>div.select_input {
            background-color: color.$unabled;
            pointer-events: inherit;
        }
    }

    &>label {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    &>div.select_input {
        background-color: white;
        cursor: pointer;
        height: 35px;
        position: relative;
        user-select: none;
        width: 100%;
        z-index: 0;

        &>input.required {
            position: absolute;
            background-color: red;
            height: 1px;
            top: calc(100% - 1px);
            left: 50%;
            transform: translateX(-50%);
            outline: none;
            opacity: 0;
            width: 100%;
        }

        &>div.placeholder {
            align-content: center;
            font-size: 14px;
            height: 100%;
            overflow: hidden;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            transition: width .3s;
            width: calc(100% - 1.1rem);

            &>* {
                max-height: 100%;
            }

            &.--default_placeholder {
                opacity: .55;
                font-size: 16px;
            }
        }

        &>.multiple_counter {
            &::after {
                content: attr(content);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 1.8rem;
                width: 18px;
                font-size: 12px;
                color: black;
                height: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 0;
                transition: opacity .2s;
            }

            &[content]::after {
                opacity: 1;
            }
        }

        &>button.--clear {
            position: absolute;
            width: 18px;
            border-radius: 50%;
            height: 18px;
            top: 50%;
            transform: translateY(-50%);
            right: .5rem;
            background-color: color.$danger;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 1;
            transition:
                right .3s,
                width .3s,
                height .3s,
                border-radius .3s,
                border-top-right-radius .3s,
                background-color .3s;

            &:hover {
                background-color: map-get($map: color.$colorsHover, $key: '.--danger');
            }

            &>svg {
                width: 10px;
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7458%) hue-rotate(328deg) brightness(99%) contrast(96%);
            }
        }
    }
}

div.select_options {
    z-index: 2001;

    &.--legacy {
        &>div.overlay>ul.options {
            animation: ul-options-close-border .3s forwards;

            &.--is_open {
                animation: none;
                border: $ulOptionsLegacyBorder;
            }

            &>li.--search {
                border-bottom: 1px solid rgba(0, 0, 0, .4);
                padding: .4rem 0 .2rem .2rem;
                box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .2);
            }

            &>li:not(.--search) {
                padding: .15rem 1rem;
            }
        }
    }

    &.--modern {
        &>div.overlay>ul.options {
            animation: ul-options-close .3s forwards;
            border-bottom-left-radius: 2px;
            border-bottom-right-radius: 2px;
            margin-top: .2rem;

            &.--is_open {
                animation: none;
                box-shadow: $ulOptionsListBoxShadow;
            }

            &>li.--search {
                border-bottom: 1px solid rgba(0, 0, 0, .4);
                padding: .4rem 0 .2rem .2rem;
                box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .2);
            }

            &>li:not(.--search) {
                padding: .3rem .5rem;
            }
        }
    }

    &>div.overlay>ul.options {
        background-color: white;
        list-style: none;
        max-height: 0px;
        overflow: auto;
        position: absolute;
        transition: max-height .3s, opacity .3s;
        width: 100%;
        z-index: 2001;

        &.--is_open {
            max-height: 336px;
        }

        &>li.--search {
            background-color: white;
            position: sticky;
            top: 0;

            &>input {
                outline: none;
                width: 100%;
            }
        }

        &>li:not(.--search) {
            align-content: center;
            cursor: pointer;
            font-size: 14px;
            height: 31px;
            overflow: hidden;
            text-overflow: ellipsis;
            transition:
                background-color .1s,
                color .1s,
                height .2s,
                padding .2s;
            user-select: none;
            white-space: nowrap;
            width: 100%;

            &>* {
                max-height: 100%;
            }

            &.--hidden {
                height: 0px;
                padding: 0;
            }

            &:hover {
                background-color: color.$primary;
                color: white;
            }

            &.--is_selected {
                background-color: color.$primaryDark;
            }
        }
    }

    &.--force_mobile {
        @include mobileSelectOptions();
    }

    @media screen and (max-width: 720px) {
        @include mobileSelectOptions();
    }
}

@keyframes ul-options-close {
    0% {
        box-shadow: $ulOptionsListBoxShadow;
    }

    99% {
        box-shadow: $ulOptionsListBoxShadow;
    }

    100% {
        box-shadow: none;
    }
}

@keyframes ul-options-close-border {
    0% {
        border: $ulOptionsLegacyBorder;
    }

    99% {
        border: $ulOptionsLegacyBorder;
    }

    100% {
        border: none;
    }
}