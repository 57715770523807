.paginator {
	display: flex;

	&>li {
		list-style: none;
		display: flex;

		&>a {
			align-content: center;
			background-color: #eee;
			color: var(--orange-senne);
			font-weight: bold;
			font-size: 14px;
			margin: 0;
			padding: 10px 12px;
			width: auto;
			text-align: center;
			transition: background-color .3s, color 0.3s;

			&:hover {
				background-color: var(--orange-hover);
			}
		}

		&.selected>a {
			background-color: var(--orange-senne);
			color: #FFF;
		}
	}
}

@media(max-width: 600px) {
	.paginator>li>a {
		font-size: 11px;
		padding: 8px 10px;
	}
}