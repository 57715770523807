@use '../Color/style.scss' as color;
@use 'sass:map';

.tabs {

    &.--modern {
        @each $color, $hex in color.$colors {
            &#{$color} {
                &>nav {
                    &>ul {
                        &>li {
                            border-color: $hex;
                            cursor: pointer;
                            background-color: rgba($hex, .03);
                            color: rgba(#222, .6);
                            
                            &.--active {
                                background-color: white;
                                color: map-get($map: color.$colorsHover, $key: $color);
                            }
                            
                            &::before {
                                background-color: $hex;
                            }

                            &:hover {
                                color: map-get($map: color.$colorsHover, $key: $color);
                            }
                        }
                    }
                }
            }
        }

        &>nav {
            &>ul {
                box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .2);
                font-weight: 600;
                width: fit-content;

                &>li {
                    background-color: white;
                    box-sizing: border-box;
                    border-top: 2px solid transparent;
                    padding: .5rem;
                    transition: background-color .3s, color .3s;
                }
            }
        }

        &>main {
            background-color: white;
            box-shadow: 1px 4px 4px 1px rgba(0, 0, 0, .12);
            border-bottom-left-radius: 2px;
            border-bottom-right-radius: 2px;
        }
    }

    &>nav {
        &>ul {
            display: flex;
            list-style: none;

            &>li {
                position: relative;
                user-select: none;
                z-index: 0;

                &.--active {
                    pointer-events: none;
                }
            }
        }
    }

    &>main {
        position: relative;
        z-index: 1;

        &>div {
            display: none;

            &.--active {
                display: block;
            }
        }
    }
}