.user_management {
	padding: .5rem;
	display: flex;
	flex-direction: column;
	gap: .5rem;

	&>.user_management_content {
		background-color: white;
		box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .2);
		border-radius: 2px;
		padding: .5rem;

		&>.user_management_pagination {
			display: flex;
			justify-content: end;
			margin-top: .5rem;

			@media screen and (max-width: 720px) {
				justify-content: center;
			}
		}
	}
}

.user_manager_filter {
	display: grid;
	grid-template-columns: repeat(4, minmax(0px, 1fr));
	gap: .5rem;

	@media screen and (max-width: 1300px) {
		grid-template-columns: repeat(2, minmax(0px, 1fr));
	}

	&>* {
		width: 100% !important;
		align-self: end;
	}

	&>.search {
		display: flex;
		border: 1px solid rgb(210, 211, 229);
		border-radius: 8px;
		align-items: center;
		justify-content: space-between;
		gap: 1rem;
		padding: 0 1rem;
		box-sizing: border-box;

		&>span {
			opacity: .5;
			font-weight: bold;
			margin-right: 1rem;
			position: relative;
			transition: opacity .3s;
			cursor: pointer;

			@media screen and (max-width: 1000px) {
				display: none;
			}

			&:hover {
				opacity: .8;
			}

			&::after {
				content: '\A';
				position: absolute;
				right: -1.5rem;
				background: url(../../images/search.svg) no-repeat center center / 15px 15px;
				width: 15px;
				height: 15px;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		input {
			border-radius: unset;
			outline: none;

			&:focus-visible {
				outline: none;
				box-shadow: unset;
				border: unset;
			}
		}
	}

	@media screen and (max-width: 720px) {
		display: flex;
		flex-direction: column;
	}
}