@use "../../../components/Color/style.scss" as color;
@use "sass:map";

.update_report__modal_content {
    padding: 1rem;

    &>header {
        font-weight: 600;
        text-align: center;
    }

    &>main {
        margin-top: 1rem;

        &>form.--is_loading {
            & select,
            & input {
                background-color: color.$unabled !important;
                cursor: not-allowed !important;

                &[type=checkbox]+label {
                    text-decoration: line-through;
                    cursor: not-allowed !important;
                }
            }

            & button {
                pointer-events: none !important;
                background-color: color.$unabled !important;
            }

            & button[type=submit] {
                padding-right: 1.8rem;
                position: relative;

                &::after {
                    content: '\A';
                    background: url(../../../assets/loader/default.png) no-repeat center center / 20px 20px;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: .2rem;
                }
            }
        }

        &>form {

            & button {
                font-size: 16px !important;
            }

            &>.fields>.params>.params_addeded>.param>.fields,
            &>.fields {
                display: flex;
                flex-direction: column;
                gap: .5rem;

                &>.field {

                    &.--hidden {
                        display: none;
                    }

                    &>label {
                        font-size: 14px;
                        font-weight: 600;
                    }

                    &>input[type='checkbox'] {
                        cursor: pointer;

                        &+label {
                            cursor: pointer;
                            margin-left: .3rem;
                            font-size: 14px;
                        }
                    }

                    &>input:not([type='checkbox']),
                    &>select {
                        background-color: transparent;
                        border-bottom: 1px solid rgba(0, 0, 0, .3);
                        box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .2);
                        font-size: 14px;
                        outline: none;
                        padding: .3rem;
                        transition: border-bottom .3s, background-color .3s;
                        width: 100%;

                        &[disabled] {
                            background-color: #eee;
                        }

                        &:hover,
                        &:focus {
                            border-bottom: 1px solid rgba(0, 0, 0, .7);
                        }
                    }

                    &>select {
                        cursor: pointer;
                    }
                }

                &>.params {
                    &>.title {
                        font-weight: 600;
                    }

                    &>.params_addeded {
                        overflow: auto;
                        max-height: 40vh;

                        &>.param {
                            border-radius: 2px;
                            margin: .5rem .2rem;
                            padding: .2rem .5rem;
                            box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .1);
                            cursor: pointer;
                            transition: box-shadow .3s;
                            position: relative;
                            min-height: 30px;

                            &::after {
                                content: '\A';
                                background: url(../../../assets/chevron-down-solid.svg) no-repeat center center / 12px 12px;
                                width: 12px;
                                height: 12px;
                                position: absolute;
                                right: .3rem;
                                top: .5rem;
                                transform: rotate(90deg);
                                transition: transform .3s;
                            }

                            &.--active {
                                box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .2);
                            }

                            &.--active {
                                &::after {
                                    transform: rotate(0deg);
                                }

                                &>.fields {
                                    max-height: 370px;
                                }
                            }

                            &>.title {
                                font-style: italic;
                                user-select: none;
                            }

                            &>.fields {
                                max-height: 0px;
                                height: fit-content;
                                overflow: hidden;
                                transition: max-height .5s;

                                &>.actions>button {
                                    width: 100%;
                                    background-color: color.$danger;
                                    border-radius: 2px;
                                    color: white;
                                    text-shadow: 1px 1px 4px rgba(0, 0, 0, .1);
                                    transition: background-color .3s;

                                    &:hover {
                                        background-color: map-get(color.$colorsHover, '.--danger');
                                    }
                                }
                            }
                        }
                    }

                    &>.actions {
                        margin: 0 .2rem;

                        &>button {
                            background-color: color.$unabledDark;
                            border-radius: 2px;
                            color: white;
                            width: 100%;
                            text-shadow: 1px 1px 4px rgba(0, 0, 0, .1);
                            transition: background-color .3s;

                            &:hover {
                                background-color: color.$unabled;
                            }
                        }
                    }
                }
            }

            &>.actions {
                margin-top: .5rem;
                display: flex;
                justify-content: right;

                &:has(>.remove_button) {
                    justify-content: space-between;
                }

                &:not(.--remove_active) {
                    &>.confirm_button,
                    &>.cancel_button {
                        display: none;
                    }
                }

                &.--remove_active {
                    &>[type=submit],
                    &>.remove_button {
                        display: none;
                    }
                }

                &>button {
                    color: white;
                    padding: .1rem .4rem;
                    border-radius: 2px;
                    font-weight: 600;
                    transition: background-color .3s, padding-right .3s;

                    &.cancel_button,
                    &.remove_button {
                        background-color: color.$danger;

                        &:hover {
                            background-color: map-get(color.$colorsHover, '.--danger');
                        }
                    }

                    &.confirm_button {
                        background-color: color.$success;

                        &:hover {
                            background-color: map-get(color.$colorsHover, '.--success');
                        }
                    }

                    &[type=submit] {
                        background-color: color.$primary;

                        &:hover {
                            background-color: map-get(color.$colorsHover, '.--primary');
                        }
                    }
                }
            }
        }
    }
}