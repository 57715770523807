@use 'sass:color' as color;

$success: #28a745;
$warning: #ffc107;
$danger: #E95C6D;
$info: #17a2b8;
$primary: #EC6726;
$primaryLight: #F3953F;
$primaryDark: #D15D20;
$secondary: #B9BD5C;
$secondaryDark: #ABB056;
$unabled: #DADADA;
$unabledDark: #C6C6C6;

$colors: (
	'.--success': $success,
	'.--warning': $warning,
	'.--danger': $danger,
	'.--info': $info,
	'.--primary': $primary,
	'.--primary--dark': $primaryDark,
	'.--primary--light': $primaryLight,
	'.--secondary': $secondary,
	'.--secondary--dark': $secondaryDark,
	'.--unabled': $unabled,
	'.--unabled--dark': $unabledDark
);

$colorsHover: (
	'.--success': color.adjust($success, $lightness: -15%),
	'.--warning': color.adjust($warning, $lightness: -15%),
	'.--danger': color.adjust($danger, $lightness: -15%),
	'.--info': color.adjust($info, $lightness: -15%),
	'.--primary': color.adjust($primary, $lightness: -15%),
	'.--primary--dark': color.adjust($primaryDark, $lightness: 15%),
	'.--primary--light': color.adjust($primaryLight, $lightness: -15%),
	'.--secondary': color.adjust($secondary, $lightness: -15%),
	'.--secondary--dark': color.adjust($secondaryDark, $lightness: 15%),
	'.--unabled': color.adjust($unabled, $lightness: -15%),
	'.--unabled--dark': color.adjust($unabledDark, $lightness: 15%)
);

$colorsFont: (
	'.--success': white,
	'.--warning': white,
	'.--danger': white,
	'.--info': white,
	'.--primary': white,
	'.--primary--dark': white,
	'.--primary--light': white,
	'.--secondary': white,
	'.--secondary--dark': white,
	'.--unabled': white,
	'.--unabled--dark': white
)