@use '../Color/style' as color;
@use 'sass:map';

.pdf_result {
    width: fit-content;
    position: relative;
    opacity: 0;
    transition: opacity .3s;

    &.--has_content {
        opacity: 1;
    }

    &__canvas {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin: 1rem 0;

        &>canvas {
            max-width: 960px;
            width: 100%;
            box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, .7);
        }
    }

    &__download {
        position: fixed;
        bottom: 5rem;
        right: 3rem;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        border: none;
        outline: line;
        box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, .7);
        cursor: pointer;
        transition: box-shadow .3s, background-color .3s, height .3s;
        background-color: color.$primary;

        &[disabled] {
            background-color: color.$unabledDark;
            cursor: default;
        }

        &.--is_loading::before {
            background-image: url(../../assets/loader/unabled.png);
            background-size: 60%;
            filter: none;

            @media screen and (max-width: 720px) {
                background-size: 7%;
            }
        }

        &:not([disabled]):hover {
            background-color: map.get(color.$colorsHover, '.--primary');
        }

        @media screen and (max-width: 720px) {
            width: 100%;
            border-radius: 0;
            left: 0;
            bottom: 0;
            box-shadow: none;
            height: 45px;
            z-index: 110;

            &:not([disabled]):hover {
                height: 50px;
            }
        }

        &::before {
            content: '\A';
            position: absolute;
            background: url(../../assets/download-solid.svg) no-repeat center center / 40% 40%;
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7452%) hue-rotate(166deg) brightness(107%) contrast(100%);
            width: inherit;
            height: inherit;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}