@import '../Color/style.scss';

.grid_attendance {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 20px;

    &>.title {
        font-size: 26px;
        color: $primaryLight;
        font-weight: 800;
    }

    &>.results>.table {
        & .--status_progress {
            &>div {
                margin: .2rem;
                padding: .3rem;
                border: 1px solid #D7D7D7;
                border-radius: 4px;
                box-sizing: border-box;
                width: 90px;

                &>div {
                    height: 10px;
                }
            }
        }

        & .patient {
            font-weight: bold;
            position: relative;
            padding-right: 1.5rem;

            &.--has_error::after {
                content: '\A';
                background: url(../../images/errorStatus.svg) no-repeat center center / 20px 20px;
                width: 20px;
                height: 20px;
                position: absolute;
                right: 0;
            }
        }
    }

    &>.attendance_footer {
        margin-top: .5rem;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: center;

            &>:first-child {
                order: 2;
            }

            &>:last-child {
                order: 1;
                margin-bottom: .5rem;
            }
        }
    }
}